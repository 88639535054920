.home {
    color: red !important;
}


/*/////////////////// MEDIA QUERIES START /////////////////////////////////*/

/*iPhone 5*/
@media (device-height: 568px) and (device-width: 320px) and (-webkit-min-device-pixel-ratio: 2) {
	
}

/*Galaxy 5s*/
@media screen and (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 3) {
	
}

/*Samsung Galaxy S9 Media Queries (In terms of Mobile only)*/

@media only screen and (min-width: 360px) and (max-width: 767px) {

}

/*iPhone XR*/
@media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
}

/*iPhone 6/7/8*/
@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
}

/*iPhone 6+/7+/8+ */
@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (-webkit-device-pixel-ratio: 3) {
}

/*iPhone X*/
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
}

/*iPhone XS*/
@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
}

/* iphone XS Max */
@media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
}

/*Galaxy 5s*/
@media screen and (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 3) {
}

/*/////////Other androids///////////*/

/*Samsung Galaxy S9 Media Queries (In terms of Mobile only)*/

@media only screen and (min-width: 360px) and (max-width: 767px) {
}

/*---LAPTOPS AND ABOVE---*/

/*-- specific for this project's <h1>--*/
@media screen and (min-device-width: 992px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
}

/*-- specific for this project's <h1>--*/
@media screen and (min-device-width: 770px) and (max-device-width: 1px) and (-webkit-min-device-pixel-ratio: 1) {
}

@media screen and (min-device-width: 690px) and (max-device-width: 769px) and (-webkit-min-device-pixel-ratio: 1) {
}

/* ----------- Non-Retina Screens ----------- */
@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
}

/* ----------- Retina Screens ----------- */
@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
}

/* ----------- Wide Screens ----------- */

@media only screen and (min-width: 1224px) {
}

@media only screen and (min-width: 1824px) {
}

@media (min-width: 2000) {
}
