#resume {
  /* width: 80vw; */
}

#education {
  scroll-margin-top: 7em;
}

.skillColumn1 div,
.skillColumn2 div {
  margin-bottom: 5%;
}

.text-in-contact-info {
  color: black;
}

#resume li {
  font-size: 100%;
  line-height: 40px;
  line-height: 160%;
  list-style-type: "- ";
  margin-bottom: 1em;
}

#resume ul li ul li {
  font-size: 100%;
}

#resume a h2.text-in-contact-info:hover {
  text-decoration: none;
  transition: 0.5s;
  background: rgba(4, 1, 130, 0.7);
  color: white !important;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
}

div#resume a:hover {
  text-decoration: none;
}

.creds {
  color: rgb(26, 26, 133);
}
.creds:hover {
  color: white;
  background: #4745ae;
  border-radius: 5px;
  transition: 0.2s;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -ms-transition: 0.2s;
  -o-transition: 0.2s;
}

@media screen and (max-width: 1000px) and (-webkit-min-device-pixel-ratio: 1) {
  #resume .contact-information {
    display: none;
  }
  div#resume img {
    display: none;
  }
  div#resume .desktop-only {
    display: none;
  }
}

@media screen and (max-width: 1556px) and (min-width: 1290px) and (-webkit-min-device-pixel-ratio: 1) {
  #resume {
    min-width: 100% !important;
  }
}

/* --------Previous--------- */
#resume,
#resume-background {
  background: url(//css-tricks.com/examples/OnePageResume/images/noise.jpg);
}

div#resume {
  /* min-width: 310px; */
  font: 16px Helvetica, Avernir, sans-serif;
  line-height: 24px;
}

div#resume h1 {
  margin: 0 0 16px 0;
  padding: 0 0 16px 0;
  font-size: 250%;
  font-weight: bold;
  letter-spacing: -2px;
  border-bottom: 1px solid #999;
  line-height: 50px;
  font-family: Ubuntu, sans-serif;
  color: #464646;
}

div#resume h2 {
  font-size: 18px;
  margin: 0 0 6px 0;
  position: relative;
}

/*  dates  */
div#resume h2 span {
  position: absolute;
  bottom: 0;
  right: 0;
  font-style: italic;
  font-family: Georgia, serif;
  font-size: 16px;
  color: #999 !important;
  font-weight: normal;
}

div#resume p {
  margin: 0 0 16px 0;
}

div#resume p.objective {
  font-family: Georgia, serif;
  font-style: italic;
  color: #666;
}

/* section on left side*/

div#resume dt {
  font-weight: bolder;
  font-size: 100%;
  text-align: right;
  padding: 0 14px 0 0;
  width: 150px;
  border-right: 1px solid rgba(105, 186, 213, 0.7);
  font-family: Ubuntu, sans-serif !important;
}
div#resume dl {
  display: table-row;
}
div#resume dl dt,
div#resume dl dd {
  display: table-cell;
  padding-bottom: 20px;
}
div#resume dl dd {
  width: 100%; /* warning */
  padding-left: 10px;
}

div#resume img.professional-picture {
  float: right;
  padding: 10px;
  background: #fff;
  margin: 0 30px;
  -webkit-transform: rotate(-4deg);
  transform: rotate(-4deg);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  width: 30%;
  max-width: 220px;
}

/* --------------- MEDIA QUERIES --------------- */

@media screen and (max-width: 1100px) {
  div#resume h2 span {
    position: static;
    display: block;
    margin-top: 2px;
  }
}

@media screen and (max-width: 550px) {
  div#resume {
    padding: 5% 0 !important;
  }

  div#resume {
    min-width: 100%;
    padding: 12px;
    overflow: hidden;
  }
  #resume p,
  li {
    margin-right: 20px;
  }
  #resume h1 {
    /*minun */
    font-size: 200% !important;
    margin-right: 0;
    line-height: 0;
    margin-bottom: -3% !important;
  }
  div#resume {
    /* width: 50vh !important; */
    overflow: hidden !important;
    padding-right: -10% !important;
  }
  #resume .deployment-details {
    display: none;
  }
  div#resume h2 span {
    position: static !important;
    display: block !important;
    margin-top: 2px;
  }
  div#resume h2 span {
    position: static !important;
    display: block !important;
    margin-top: 2px;
    font-size: 70% !important;
  }

  #resume dt {
    font-size: 90% !important;
  }

  #resume h2 strong {
    font-size: 90% !important;
  }
}

@media screen and (max-width: 400px) {
  div#resume dl dt {
    border-right: none;
    border-bottom: 1px solid #999;
  }
  div#resume dl,
  div#resume dl dd,
  div#resume dl dt {
    display: block;
    padding-left: 0;
    margin-left: 0;
    padding-bottom: 0;
    text-align: left;
    width: 100%;
  }
  div#resume dl dd {
    margin-top: 6px;
  }
  div#resume h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
  }
  div#resume dt {
    font-size: 20px;
  }
  h1 {
    font-size: 36px;
    margin-right: 0;
    line-height: 0;
  }
  div#resume img {
    margin: 0;
  }
  body {
    margin: 0;
  }

  div#resume {
    /* width: 320px; */
    padding: 12px;
    overflow: hidden;
  }
  #resume p,
  li {
    margin-right: 20px;
  }
  #resume h1 {
    /*minun */
    font-size: 200% !important;
    margin-right: 0;
    line-height: 0;
    margin-bottom: -3% !important;
  }
  div#resume {
    /* width: 50vh !important; */
    overflow: hidden !important;
    padding-right: -10% !important;
  }
  #resume .deployment-details {
    display: none;
  }
}

@media screen and (max-width: 320px) {
  body {
    margin: 0;
  }

  div#resume {
    /* width: 320px; */
    padding: 12px;
    overflow: hidden;
  }
  #resume p,
  li {
    margin-right: 20px;
  }
  #resume h1 {
    /*minun */
    font-size: 200% !important;
    margin-right: 0;
    line-height: 0;
    margin-bottom: -3% !important;
  }
  div#resume {
    /* width: 50vh !important; */
    overflow: hidden !important;
    padding-right: -10% !important;
  }
  #resume .deployment-details {
    display: none;
  }
}

/*----- other media queries-----*/
@media (max-width: 575.98px) {
  #resume i {
    font-size: 105% !important;
  }
  div#resume {
    /* width: 320px; */
    padding: 12px;
    overflow: hidden;
  }
  #resume p,
  li {
    margin-right: 20px;
  }
  #resume h1 {
    /*minun */
    font-size: 200% !important;
    margin-right: 0;
    line-height: 0;
    margin-bottom: -3% !important;
  }
  div#resume {
    /* width: 45vh !important; */
    overflow: hidden !important;
    padding-right: -10% !important;
  }
  #resume .deployment-details {
    display: none;
  }
}

/* Large devices (desktops, less than 1200px) */
@media (min-width: 1290px) {
  #resume {
    padding: 3% !important;
    /* max-width: 70vw !important; */
  }
}

/* 1792x828px at 326ppi XR 1 */
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
  div#resume h2 span {
    position: static;
    display: block;
    margin-top: 2px;
  }
}

/*XR 2*/
@media only screen and (min-width: 414px) and (max-width: 767px) {
  #resume {
    max-width: 100%;
  }

  #resume i {
    font-size: 275% !important;
  }

  div#resume {
    /* width: 320px; */
    padding: 12px;
    overflow: hidden;
  }
  #resume p,
  li {
    margin-right: 20px;
  }
  #resume h1 {
    font-size: 200% !important;
    margin-right: 0;
    line-height: 0;
    margin-bottom: -3% !important;
  }
  div#resume {
    /* width: 45vh !important; */
    overflow: hidden !important;
    padding-right: -10% !important;
  }
  #resume .deployment-details {
    display: none;
  }

  div#resume h2 span {
    position: static !important;
    display: block !important;
    margin-top: 2px;
    font-size: 70% !important;
  }

  #resume dt {
    font-size: 90% !important;
  }

  #resume h2 strong {
    font-size: 90% !important;
  }
}
