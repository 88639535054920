div.bg-transparent.container {
  padding: 0;
}

.carousel-overlay-text {
  z-index: 98;
  position: absolute;
  left: 50%;
  top: 70%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}

.Typewriter__wrapper {
  text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.5);
  position: relative;
}

.Typewriter {
  color: white;
  text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.5);
  font-family: "Roboto Mono", monospace;
  z-index: 99;
  font-size: 100%;
  opacity: 0.9;
  font-weight: 300;
}

/*/////////////////// MEDIA QUERIES START ////////////////////////////
/////*/

@media only screen and (max-width: 1150px) {
  .carousel-overlay-text {
    top: 50%;
  }
}

@media only screen and (max-width: 966px) {
  .carousel-overlay-text {
    top: 45%;
  }
}

@media only screen and (max-width: 775px) {
  .carousel-overlay-text {
    top: 35%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
  }

  .Typewriter {
    font-size: 70%;
    opacity: 1;
  }
}

@media only screen and (max-width: 620px) {
  .carousel-overlay-text {
    top: 30%;
    left: 52%;
  }

  .Typewriter {
    font-size: 45%;
    opacity: 1;
  }
}
