.teachingPicture {
    max-width: 600px;
    border-radius: 10px;
}

.textColumnPic {
    margin: auto 0 auto 0;
}

@media screen and (max-width: 1100px) {

    .teachingPicture {
        max-width: 100%;
    }
    .textColumnPic {
        margin: 0;
    }
}